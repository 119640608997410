#PocketSyncStatus.MainDisplayModule > .Header > .Inline > .Action {
  display: inline-block;
  cursor: pointer;
  color: #888;
}

#PocketSyncStatus.MainDisplayModule > .Header > .Inline > .Action:hover {
  color: #aaa;
}

  #PocketSyncStatus.MainDisplayModule > .Header > .Inline > .Action > .Icon {
    vertical-align: middle;
  }

    #PocketSyncStatus.MainDisplayModule > .Header > .Inline > .Action > .Icon > svg {
      height: .85em;
      fill: currentColor;
    }

  #PocketSyncStatus.MainDisplayModule > .Header > .Inline > .Action > .Label {
    margin-left: .25em;
    text-decoration-line: underline;
    text-decoration-thickness: 1px;
    text-decoration-color: orange;
    vertical-align: text-top;
  }

  @media screen and (max-width: 420px) {
    #PocketSyncStatus.MainDisplayModule > .Header > .Inline > .Action > .Label {
      max-width: 75%;
    }
  }

    #PocketSyncStatus.MainDisplayModule > .Header > .Inline > .Action > .Label > p {
      margin: 0;
    }

  #PocketSyncStatus.MainDisplayModule > .Header > .Inline > .Action > .Icon,
  #PocketSyncStatus.MainDisplayModule > .Header > .Inline > .Action > .Label {
    display: inline-block;
  }

  #PocketSyncStatus.MainDisplayModule > .Header > .Inline > .Action.disabled > .Label {
    text-decoration: none;
    cursor: default;
  }

#PocketSyncStatus.MainDisplayModule > .Expanded {
}

  #PocketSyncStatus.MainDisplayModule > .Expanded > .Title {
    font-size: 1.5em;
    font-weight: 900;
    letter-spacing: -1px;
  }

    #PocketSyncStatus.MainDisplayModule > .Expanded > .Title > .Icon {
      padding-right: .35em;
      vertical-align: middle;
    }

      #PocketSyncStatus.MainDisplayModule > .Expanded > .Title > .Icon > svg {
        height: 1.44em;
        color: #000;
      }