#ContentStats.MainDisplayModule > .Expanded {
}

  #ContentStats.MainDisplayModule > .Expanded > .Title {
    font-size: 1.5em;
    font-weight: 900;
    letter-spacing: -1px;
  }

    #ContentStats.MainDisplayModule > .Expanded > .Title > .Icon {
      padding-right: .35em;
      vertical-align: middle;
    }

      #ContentStats.MainDisplayModule > .Expanded > .Title > .Icon > svg {
        height: 1.44em;
        color: #000;
      }

   #ContentStats.MainDisplayModule > .Expanded > .ExpandedViewItemGroup {
   }

    #ContentStats.MainDisplayModule > .Expanded > .ExpandedViewItemGroup > .ExpandedViewItem {
      overflow: hidden;
    }

    #ContentStats.MainDisplayModule > .Expanded > .ExpandedViewItemGroup > .ExpandedViewItem.align-right {
      float: right;
      margin-right: 0;
    }

    #ContentStats.MainDisplayModule > .Expanded > .ExpandedViewItemGroup > .ExpandedViewItem.SavesCount,
    #ContentStats.MainDisplayModule > .Expanded > .ExpandedViewItemGroup > .ExpandedViewItem.WordsRead {
      box-sizing: border-box;
      display: inline-block;
      border: 1px solid #000;
      padding: 1em;
      width: 48%;
      font-weight: 900;
      font-size: 1.24em;
      letter-spacing: -1px;
      margin-bottom: 1em;
      margin-right: 0;
      text-align: center;
      min-height: 4em;
    }

    #ContentStats.MainDisplayModule > .Expanded > .ExpandedViewItemGroup > .ExpandedViewItem.WordsRead {
      margin-left: 4%;
    }

      #ContentStats.MainDisplayModule > .Expanded > .ExpandedViewItemGroup > .ExpandedViewItem > .Icon {
      }

      #ContentStats.MainDisplayModule > .Expanded > .ExpandedViewItemGroup > .ExpandedViewItem > .Icon,
      #ContentStats.MainDisplayModule > .Expanded > .ExpandedViewItemGroup > .ExpandedViewItem > .Label {
        display: inline-block;
        overflow: hidden;
        float: left;
      }

      #ContentStats.MainDisplayModule > .Expanded > .ExpandedViewItemGroup > .ExpandedViewItem.SavesCount > .Icon,
      #ContentStats.MainDisplayModule > .Expanded > .ExpandedViewItemGroup > .ExpandedViewItem.SavesCount > .Label,
      #ContentStats.MainDisplayModule > .Expanded > .ExpandedViewItemGroup > .ExpandedViewItem.WordsRead > .Icon,
      #ContentStats.MainDisplayModule > .Expanded > .ExpandedViewItemGroup > .ExpandedViewItem.WordsRead > .Label {
        float: none;
      }

      #ContentStats.MainDisplayModule > .Expanded > .ExpandedViewItemGroup > .ExpandedViewItem.SavesCount > .Icon,
      #ContentStats.MainDisplayModule > .Expanded > .ExpandedViewItemGroup > .ExpandedViewItem.WordsRead > .Icon {
        vertical-align: 0em;
      }


      #ContentStats.MainDisplayModule > .Expanded > .ExpandedViewItemGroup > .ExpandedViewItem > .Label {
        margin-left: .25em;
      }

    #ContentStats.MainDisplayModule > .Expanded > .ExpandedViewItemGroup > .StatsPercentBar {
      width: 100%;
      overflow: hidden;
      margin-bottom: 1em;
      display: flex;
    }

      #ContentStats.MainDisplayModule > .Expanded > .ExpandedViewItemGroup > .StatsPercentBar > span {
        box-sizing: border-box;
        display: inline-block;
        position: relative;
        padding: .25em .5em;
        letter-spacing: -1px;
        overflow: hidden;
        line-height: 1em;
      }

      #ContentStats.MainDisplayModule > .Expanded > .ExpandedViewItemGroup > .StatsPercentBar > .Target {
        float: left;
        z-index: 10;
      }

      #ContentStats.MainDisplayModule > .Expanded > .ExpandedViewItemGroup > .StatsPercentBar > .Alt {
        text-align: right;
        float: right;
      }

    #ContentStats.MainDisplayModule > .Expanded > .ExpandedViewItemGroup > .FocalStatBlock {
      display: flex;
      flex-wrap: wrap;
      gap: 5%;
      margin-bottom: 2em;
      text-align: center;
      vertical-align: middle;
      overflow: hidden;
    }

      #ContentStats.MainDisplayModule > .Expanded > .ExpandedViewItemGroup > .FocalStatBlock > .FocalStat {
        flex: 0 0 47.5%;
        font-size: 1.44em;
        padding: 2em .5em;
        line-height: .7em;
        letter-spacing: -1px;
        background-color: #fff;
        border: 1px solid #aaa;
        box-sizing: border-box;
        margin-bottom: 2.5vw;
      }

      @media screen and (max-width: 360px) {
         #ContentStats.MainDisplayModule > .Expanded > .ExpandedViewItemGroup > .FocalStatBlock > .FocalStat {
          flex: 0 0 100%;
        }
      }

        #ContentStats.MainDisplayModule > .Expanded > .ExpandedViewItemGroup > .FocalStatBlock > .FocalStat > .Stat {
          font-weight: 900;
        }

        #ContentStats.MainDisplayModule > .Expanded > .ExpandedViewItemGroup > .FocalStatBlock > .FocalStat > .Label {
          color: #aaa;
          letter-spacing: -2px;
        }