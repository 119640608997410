.MainDisplayModule {
  display: block;
  box-sizing: border-box;
  padding: 1em 2em;
  border-top: 1px solid #eee;
}

.MainDisplayModule:first-of-type {
  border-top: 0;
}

  .MainDisplayModule > .Header {
    padding: 1em 0;
    overflow: hidden;
  }

    .MainDisplayModule > .Header > .Label {
      display: inline-block;
      font-weight: 900;
      font-size: 1.2em;
      margin-right: 2em;
      width: 55px;
      float: left;
      line-height: .75em;
    }

    @media screen and (max-width: 450px) {
      .MainDisplayModule > .Header > .Label {
        font-size: 1em;
        width: 30px;
        line-height: 1em;
      }
    }

    .MainDisplayModule > .Header > .Inline {
      display: inline-block;
      color: #aaa;
      font-size: .85em;
      float: left;
      max-width: calc(100% - 2em - 55px - 2em - 1em); /* full-width - label padding - label width - toggle padding - toggle width */
      line-height: 1.375em;
      overflow: hidden;
    }

    .MainDisplayModule > .Header > .ToggleView {
      display: inline-block;
      color: #ddd;
      font-size: 1em;
      float: right;
      cursor: pointer;
      vertical-align: middle;
      transition: color .3s ease-in-out;
    }

    .MainDisplayModule > .Header > .ToggleView:hover {
      color: #eee;
    }

      .MainDisplayModule > .Header > .ToggleView > svg {
        height: .85em;
      }

    @media screen and (max-width: 450px) {
      .MainDisplayModule > .Header > .ToggleView {
        line-height: 1.5em;
      }
    }

  .MainDisplayModule > .Expanded {
    padding: 0 18%;
  }

  @media screen and (max-width: 450px) {
    .MainDisplayModule > .Expanded {
      padding: 0 5%;
    }
  }

    .MainDisplayModule > .Expanded > .Title {
      margin-top: 1em;
    }