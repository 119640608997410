.MainLanding {

}

  .MainLanding .LogoBlock {
    width: 64%;
    margin: 0 auto;
    display: block;
    text-align: center;
  }

    .MainLanding .LogoBlock > span {
      float: none;
    }

    .MainLanding .LogoBlock > .Wordmark {
      letter-spacing: -1px;
    }