.ExpandedViewLabel {
}

  .ExpandedViewLabel > .Title {
    margin-bottom: .44em;
  }

    .ExpandedViewLabel > .Title > span {
      background-color: #eee;
      display: inline-block;
      padding: 0 .5em .15em .5em;
      border-radius: .5em;
      line-height: 1.4em;
      color: #888;
      vertical-align: middle;
    }
