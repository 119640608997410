#ContentList.MainDisplayModule > .Expanded {
}

  #ContentList.MainDisplayModule > .Expanded > .Title {
    font-size: 1.5em;
    font-weight: 900;
    letter-spacing: -1px;
  }

    #ContentList.MainDisplayModule > .Expanded > .Title > .Icon {
      vertical-align: middle;
      padding-right: .5em;
    }

      #ContentList.MainDisplayModule > .Expanded > .Title > .Icon > svg {
        height: 1.1em;
      }

  #ContentList.MainDisplayModule > .Expanded > .FiltersList {
    margin-top: 3em;
  }

    #ContentList.MainDisplayModule > .Expanded > .FiltersList > .ActiveFilterToggle {
      display: inline-block;
      cursor: pointer;
      margin-bottom: 1em;
      margin-right: 2.4em;
      text-decoration-line: underline;
      text-decoration-thickness: 1px;
      text-decoration-color: orange;
    }

      #ContentList.MainDisplayModule > .Expanded > .FiltersList > .ActiveFilterToggle > .Label {
      }

        #ContentList.MainDisplayModule > .Expanded > .FiltersList > .ActiveFilterToggle > .Label > .Category {
          color: #aaa;
        }

        #ContentList.MainDisplayModule > .Expanded > .FiltersList > .ActiveFilterToggle > .Label > .CurrentValue {
          color: orange;
        }

#ContentList.MainDisplayModule .PocketContent > .ExpandedViewItem {
  display: block;
  margin: 0;
  overflow: hidden;
}

#ContentList.MainDisplayModule > .Expanded > .PaginationControls {
  color: #aaa;
  text-align: center;
}

  #ContentList.MainDisplayModule > .Expanded > .PaginationControls > .ViewPreviousResults,
  #ContentList.MainDisplayModule > .Expanded > .PaginationControls > .ViewNextResults {
    display: inline-block;
    width: 25%;
  }

  #ContentList.MainDisplayModule > .Expanded > .PaginationControls > .ViewPreviousResults {
    margin-right: .5em;
    vertical-align: middle;
  }

  #ContentList.MainDisplayModule > .Expanded > .PaginationControls > .ViewNextResults {
    margin-left: .5em;
    vertical-align: middle;
  }

    #ContentList.MainDisplayModule > .Expanded > .PaginationControls > .ViewPreviousResults > svg,
    #ContentList.MainDisplayModule > .Expanded > .PaginationControls > .ViewNextResults > svg {
      height: 1.44em;
      color: #ddd;
      cursor: pointer;
    }

    #ContentList.MainDisplayModule > .Expanded > .PaginationControls > .ViewPreviousResults > svg:hover,
    #ContentList.MainDisplayModule > .Expanded > .PaginationControls > .ViewNextResults > svg:hover {
      color: #eee;
    }

#ContentList.MainDisplayModule > .Expanded > .ExpandedViewItemGroup > .PocketContent {
  border-bottom: 1px solid #eee;
}

#ContentList.MainDisplayModule > .Expanded > div > .CollapsibleContainer {
  margin: .42em 0;
}

#ContentList.MainDisplayModule > .Expanded > div > .CollapsibleContainer::first-of-type {
  margin-top: 0;
}

#ContentList.MainDisplayModule > .Expanded > div > .CollapsibleContainer.collapsed {
  margin-bottom: 0;
}

#ContentList.MainDisplayModule > .Expanded > div > .CollapsibleContainer > .CollapsibleContainerToggleWrapper {
  cursor: pointer;
}

    #ContentList.MainDisplayModule > .Expanded > div > .CollapsibleContainer.collapsed > .CollapsibleContainerToggleWrapper:hover > .ExpandedViewLabel > .Title > span,
    #ContentList.MainDisplayModule > .Expanded > div > .CollapsibleContainer > .CollapsibleContainerToggleWrapper > .ExpandedViewLabel > .Title > span {
      background-color: #ccc;
    }

    #ContentList.MainDisplayModule > .Expanded > div > .CollapsibleContainer.collapsed > .CollapsibleContainerToggleWrapper > .ExpandedViewLabel > .Title > span {
      background-color: #eee;
    }

    #ContentList.MainDisplayModule > .Expanded > div > .CollapsibleContainer.collapsed > .CollapsibleContainerToggleWrapper > .ExpandedViewLabel.active > .Title > span {
      color: orange;
    }