.CollapsibleContainer {
  display: inline-block;
  overflow: hidden;
  float: left;
  clear: both;
  width: 100%;
}

.CollapsibleContainer.collapsed {
  display: block;
  clear: none;
  width: auto;
  margin-bottom: 0;
}

  .CollapsibleContainer > .CollapsibleContainerToggleWrapper {
    margin-right: 1em;
  }
