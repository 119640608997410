.ExpandedViewItem {
  display: inline-block;
  margin-right: 1em;
}

.ExpandedViewItem.clickable {
  cursor: pointer;
}

.ExpandedViewItem.clickable:hover {
  transition: color ease-out .25s;
  color: #aaa;
}

  .ExpandedViewItem > .Icon,
  .ExpandedViewItem > .Label {
    display: inline-block;
    line-height: 1.25em;
  }

  .ExpandedViewItem > .Icon {
    vertical-align: middle;
  }

    .ExpandedViewItem > .Icon > svg {
      height: .85em;
    }

  .ExpandedViewItem.clickable > .Label {
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
    text-decoration-color: orange;
  }