#PocketUser.MainDisplayModule > .Header > .Inline > .Action {
  display: inline-block;
  cursor: pointer;
  color: #888;
}

#PocketUser.MainDisplayModule > .Header > .Inline > .Action:hover {
  color: #aaa;
}

  #PocketUser.MainDisplayModule > .Header > .Inline > .Action > .Icon {
  }

    #PocketUser.MainDisplayModule > .Header > .Inline > .Action > .Icon > svg {
      fill: currentColor;
      height: 0.85em;
    }

  #PocketUser.MainDisplayModule > .Header > .Inline > .Action > .Label {
    margin-left: .25em;
    text-decoration-line: underline;
    text-decoration-color: orange;
    text-decoration-thickness: 1px;
  }

#PocketUser.MainDisplayModule > .Expanded {
}

  #PocketUser.MainDisplayModule > .Expanded > .Title {
    font-size: 1.5em;
    font-weight: 900;
    letter-spacing: -1px;
  }

    #PocketUser.MainDisplayModule > .Expanded > .Title > .Icon {
      padding-right: .35em;
      vertical-align: middle;
    }

      #PocketUser.MainDisplayModule > .Expanded > .Title > .Icon > svg {
        height: 1.34em;
      }