.PocketContent {
  padding: 1em 0;
  margin-bottom: 2em;
}

  .PocketContent > .Header,
  .PocketContent > .Metadata,
  .PocketContent > .Excerpt,
  .PocketContent > .Images,
  .PocketContent > .Tags {
    padding: 0 2.25em;
  }

  .PocketContent > .Excerpt,
  .PocketContent > .Images {
    margin-top: 1em;
  }

  .PocketContent > .Header {
  }

    .PocketContent > .Header > .Title {
      display: inline-block;
      vertical-align: text-top;
      margin-bottom: .15em;
      line-height: 1.15em;
    }

    .PocketContent > .Header > .Title.spaceless-title {
      word-break: break-all;
    }

  .PocketContent > .Metadata {
    overflow: hidden;
  }

    .PocketContent > .Metadata > .MetadataItem {
      display:  inline-block;
      max-width: 100%;
      font-size: 0.85em;
      margin-right: 1em;
      color: #ddd;
      margin-bottom: .15em;
      float: left;
    }

    .PocketContent > .Metadata > .MetadataItem.Domain {
      color: #aaa;
    }

    .PocketContent > .Metadata > .MetadataItem.NotParsed,
    .PocketContent > .Metadata > .MetadataItem.WordCount.no-words {
      color: orangered;
    }

  .PocketContent > .Excerpt {
  }

    .PocketContent > .Excerpt > p {
      font-size: 0.85em;
      color: #ccc;
    }

  .PocketContent > .Actions {
    margin-top: 1em;
    overflow: hidden;
    text-align: right;
  }

    .PocketContent > .Actions > .ExpandedViewItem,
    .PocketContent > .Actions > .Action {
      color: #aaa;
      font-size: 0.85em;
      margin-right: 1em;
      display: inline-block;
    }

      .PocketContent > .Actions > .ExpandedViewItem > .Label {
        text-decoration-thickness: 1px;
      }

      .PocketContent > .Actions > .Action > .Label {
        margin-left: .25em;
        text-decoration-line: underline;
        text-decoration-thickness: 1px;
        text-decoration-color: orange;
      }

      .PocketContent > .Actions > .Action > .InlineIcon {
        vertical-align: middle;
      }

        .PocketContent > .Actions > .Action > .InlineIcon > svg {
          height: 1.15em;
          fill: #aaa;
        }

        .PocketContent > .Actions > .Action > .InlineIcon.TallIcon > svg {
          height: 0.95em;
        }

  .PocketContent > .Images {
    overflow: hidden;
  }

    .PocketContent > .Images > a > img {
      display: inline-block;
      float: left;
      max-width: 8em;
      max-height: 8em;
      margin-right: 1em;
      margin-bottom: 1em;
      border: 2px solid #eee;
    }

  .PocketContent > .Tags {
    margin-top: .64em;
  }

    .PocketContent > .Tags > .Tag {
      display: inline-block;
      font-size: 0.85em;
      margin-right: .5em;
      color: #aaa;
      padding: 0.125em .75em;
      border: 1px solid #ddd;
      border-radius: .5em;
      margin-bottom: .25em;
    }
