.LogoBlock {
  display: inline-block;
  overflow: hidden;
}

  .LogoBlock > .Logo,
  .LogoBlock > .Wordmark {
    display: inline-block;
    line-height: 2em;
    float: left;
  }

  .LogoBlock > .Logo {
    margin-right: .24em;
  }

    .LogoBlock > .Logo > svg {
      vertical-align: text-top;
      fill: orange;
      height: 1.44em;
    }

  .LogoBlock > .Wordmark {
    font-weight: 900;
  }

    .LogoBlock > .Wordmark > .WordmarkChar {
      color: #000;
      transition: color 1s ease-in-out;
    }

    .LogoBlock > .Wordmark > .WordmarkChar.index-1,
    .LogoBlock > .Wordmark > .WordmarkChar.index-6,
    .LogoBlock > .Wordmark > .WordmarkChar.index-12 {
    }

    .LogoBlock > .Wordmark > .WordmarkChar.index-2,
    .LogoBlock > .Wordmark > .WordmarkChar.index-3,
    .LogoBlock > .Wordmark > .WordmarkChar.index-4,
    .LogoBlock > .Wordmark > .WordmarkChar.index-5,
    .LogoBlock > .Wordmark > .WordmarkChar.index-7,
    .LogoBlock > .Wordmark > .WordmarkChar.index-8,
    .LogoBlock > .Wordmark > .WordmarkChar.index-9,
    .LogoBlock > .Wordmark > .WordmarkChar.index-10,
    .LogoBlock > .Wordmark > .WordmarkChar.index-11,
    .LogoBlock > .Wordmark > .WordmarkChar.index-13,
    .LogoBlock > .Wordmark > .WordmarkChar.index-14 {
    }